import React, { useEffect, useState } from 'react';
import { LayoutProvider } from '../components/layout';
import { Typography } from '../theme';

interface ICategoryUrl {
  pageContext: {
    oldUrl: Array<{
      node: {
        category: string;
        slug: string;
      };
    }>;
  };
}

const OldCategoryUrl = (props: ICategoryUrl) => {
  const [newUrl, setNewUrl] = useState('');
  const [timer, setTimer] = useState(false);
  
  const urlObj = props.pageContext.oldUrl[0].node;

  useEffect(() => {
    if (typeof window === 'undefined' || !window.document) {
      return;
    } else {
      const protocol = window.location.protocol;
      const hostName = window.location.hostname;
      const port = window.location.port;
      let urlTemp = '';
      if (port != '') {
        urlTemp = protocol + '//' + hostName + ':' + port + '/categories/' + urlObj.slug;
      } else {
        urlTemp = protocol + '//' + hostName + '/categories/' + urlObj.slug;
      }
      setNewUrl(urlTemp);
      setTimer(true);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.location.replace(newUrl);
    }, 800);
  }, [timer]);

  return (
    <LayoutProvider
      seo
      locationSearch={props.location.search}
      redirectNewUrl={'/categories/' + urlObj.slug}
      titleExt={urlObj.category}
    >

      <Typography $fontFamily={'Montserrat'} $weight={400} $tagType={'body'} $textAlign={'center'}>
        Please wait while you are redirected to the new URL
        {/* <a href={newUrl}>{newUrl}</a> */}
      </Typography>
    </LayoutProvider>
  );
};

export default OldCategoryUrl;
